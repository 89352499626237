





































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";

import { Input, Button, Table, TableColumn } from "element-ui";

import {
  MetaModule as metaStore,
  DriverModule as driverStore,
} from "@/store/modules";

import { AdminRouter } from "@/utils/routePathContsant";
import helpers from "@/utils/helpers";
import { getApi } from "@/store/api/api";
import { Action } from "@/store/actionType";

@Component({
  components: {
    Input,
    Button,
    Table,
    TableColumn,
  },
})
export default class DutyOfficeClass extends Vue {
  palikas: any[] = [];
  driverList: any[] = [];
  districtName: any = [];

  filter: {
    page: number;
    name?: string;
    company_name?: string;
    district?: string;
    palika?: string;
    owner_id?: any;
  } = {
    page: 1,
  };

  momentDate(date: any) {
    return helpers.toMomentFormat(date);
  }

  get AdminRouter() {
    return AdminRouter;
  }

  get districts() {
    return metaStore.districtL;
  }

  async created() {
    const owner_id = this.$route.query["vehicle_owner"];
    this.filter.owner_id = owner_id;
    metaStore.loadDis();
    this.search(this.filter);
  }

  async filterPalika() {
    this.filter.palika = "";
    if (this.filter.district) {
      this.palikas = await metaStore.loadMuncipality(
        parseInt(this.filter.district)
      );
    }
  }

  async filterDriverList() {
    this.filter.page = 1;
    this.search(this.filter);
  }

  async clearFilter() {
    this.filter = { page: 1 };
    this.search(this.filter);
  }

  async onPageChange(val: number) {
    this.filter.page = val;
    this.search(this.filter);
  }

  async search(filter: any) {
    await driverStore.loadAllDriver(filter);
    this.driverList = driverStore.driverList;
  }

  async downloadExcel() {
    let query = `?page=&full_name=${this.filter.name}&profile__company_name=${this.filter.company_name}&profile__district=${this.filter.district}&profile__palika=${this.filter.palika}`;
    const action = Action.drivercsv + query;
    const sheet_path = await getApi(action);
    const link = document.createElement("a");
    link.setAttribute("href", sheet_path.excelSheetPath);
    link.click();
  }
}
